import _toConsumableArray from "C:/Users/33043/Desktop/new/super_admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import lhc_data from "./lhc_data.js";
export default {
  components: {},
  props: ["game_info_data", "title", "chang_long", "sum", "balls", "zupai", "is_manlet", "pl_value", "pk", "szsz"],
  data: function data() {
    return lhc_data;
  },
  created: function created() {// console.log(this.game_info_data);
  },
  methods: {
    //中奖号码标记颜色
    tableRowClassName: function tableRowClassName(colum) {
      // console.log(this.game_info_data);
      if (this.game_info_data.nn == this.game_info_data.upopenphase) {
        // if (this.game_info_data.isopen == 0 && this.game_info_data.nn == this.game_info_data.upopenphase) {
        var upopennumber = this.game_info_data.upopennumber;
        var result_res = this.game_info_data.result_res;

        if (this.title == "特码A+B" || this.title == "特码A" || this.title == "特码B") {
          if (this.game_info_data.upopennumber[6].num == colum.row.name) {
            return "themeColorhalf-row";
          }

          if (this.game_info_data.upopennumber[6].color == colum.row.color && (colum.row.name == "红波" || colum.row.name == "绿波" || colum.row.name == "蓝波")) {
            return "themeColorhalf-row";
          }
        }

        if (this.title == "两面" || this.title == "特码A+B" || this.title == "特码A" || this.title == "特码B") {
          for (var key in result_res) {
            if (result_res[key] == colum.row.name && key != "sum" && key != "sxl" && key != "wsl") {
              return "themeColorhalf-row";
            }
          }

          if (result_res.tema_ds == "双" && colum.row.name == "特双") {
            return "themeColorhalf-row";
          }

          if (result_res.tema_ds == "单" && colum.row.name == "特单") {
            return "themeColorhalf-row";
          }

          if (result_res.tema_dx == "大" && colum.row.name == "特大") {
            return "themeColorhalf-row";
          }

          if (result_res.tema_dx == "小" && colum.row.name == "特小") {
            return "themeColorhalf-row";
          }

          if (result_res.sum_ds == "总和单" && colum.row.name == "总单") {
            return "themeColorhalf-row";
          }

          if (result_res.sum_ds == "总和双" && colum.row.name == "总双") {
            return "themeColorhalf-row";
          }

          if (result_res.sum_dx == "总和大" && colum.row.name == "总大") {
            return "themeColorhalf-row";
          }

          if (result_res.sum_dx == "总和小" && colum.row.name == "总小") {
            return "themeColorhalf-row";
          }

          var hw_num = "";

          if (this.game_info_data.upopennumber[6].num < 10) {
            hw_num = this.game_info_data.upopennumber[6].num;
          } else {
            hw_num = this.game_info_data.upopennumber[6].num[0] * 1 + this.game_info_data.upopennumber[6].num[1] * 1;
          }

          if (hw_num <= 4 && colum.row.name == "合尾小") {
            return "themeColorhalf-row";
          }

          if (hw_num > 4 && colum.row.name == "合尾大") {
            return "themeColorhalf-row";
          }

          if (this.game_info_data.upopennumber[6].num * 1 == 25) {
            return "";
          }
        }

        if (this.title == "连码" || this.title == "自选不中") {
          for (var index = 0; index < upopennumber.length; index++) {
            if (upopennumber[index].num == colum.row.name) {
              return "themeColorhalf-row";
            }
          }
        }

        if (this.title == "尾数" || this.title == "尾连") {
          if (this.balls == "ws" || this.balls == "wl") {
            for (var _index = 0; _index < upopennumber.length; _index++) {
              if (colum.row.namenum == upopennumber[_index].num.toString().slice(-1)) {
                return "themeColorhalf-row";
              }
            }
          }

          if (this.balls == "wsbz" || this.balls == "wlbz") {
            var list = [];

            for (var _index2 = 0; _index2 < upopennumber.length; _index2++) {
              // if (upopennumber[index].num == 10) {
              //     list.push(0)
              // } else {
              // }
              list.push(upopennumber[_index2].num.toString().slice(-1));
            } // console.log(upopennumber,list);


            if (!list.includes(colum.row.namenum)) {
              return "themeColorhalf-row";
            }
          }
        }

        if (this.title == "色波") {
          var nums = colum.row.nums;

          if (nums != undefined) {
            for (var j = 0; j < nums.length; j++) {
              if (this.game_info_data.upopennumber[6].num == nums[j]) {
                return "themeColorhalf-row";
              }
            }
          } else {
            if (this.game_info_data.upopennumber[6].num == colum.row.name) {
              return "themeColorhalf-row";
            }
          }

          if (this.game_info_data.upopennumber[6].color == colum.row.color && (colum.row.name == "红波" || colum.row.name == "绿波" || colum.row.name == "蓝波")) {
            return "themeColorhalf-row";
          }
        }

        if (this.title == "特肖") {
          if (colum.row.name == upopennumber[6].sx) {
            return "themeColorhalf-row";
          }
        }

        if (this.title == "一肖" || this.title == "连肖" || this.title == "六肖一中") {
          if (this.balls == "yxbz" || this.balls == "lx2bz" || this.balls == "lx3bz" || this.balls == "lx4bz" || this.balls == "lx5bz") {
            if (!upopennumber.find(function (item) {
              return item.sx == colum.row.name;
            })) {
              return "themeColorhalf-row";
            }
          } else {
            for (var _index3 = 0; _index3 < upopennumber.length; _index3++) {
              if (upopennumber[_index3].sx == colum.row.name) {
                return "themeColorhalf-row";
              }
            }
          }
        }

        if (this.title == "正码/正特") {
          var zm_upopennumber = JSON.parse(JSON.stringify(upopennumber));
          zm_upopennumber.length = 6;

          if (this.balls == "zm") {
            for (var _index4 = 0; _index4 < zm_upopennumber.length; _index4++) {
              if (zm_upopennumber[_index4].num == colum.row.name) {
                return "themeColorhalf-row";
              }
            }
          }

          if (this.balls == "ztm1" || this.balls == "ztm2" || this.balls == "ztm3" || this.balls == "ztm4" || this.balls == "ztm5" || this.balls == "ztm6") {
            var _index5 = this.balls[3] * 1 - 1; // console.log(zm_upopennumber[index].num);


            if (zm_upopennumber[_index5].num == colum.row.name) {
              return "themeColorhalf-row";
            }
          }
        }

        if (this.title == "正码1-6") {
          var _zm_upopennumber = JSON.parse(JSON.stringify(upopennumber));

          _zm_upopennumber.length = 6;

          for (var _index6 = 0; _index6 < _zm_upopennumber.length; _index6++) {
            if (_zm_upopennumber[_index6].num != 49) {
              if (_zm_upopennumber[_index6].num >= 25 && _index6 + 1 == colum.row.game_type.substr(-1) && colum.row.name == "大") {
                return "themeColorhalf-row";
              }

              if (_zm_upopennumber[_index6].num <= 24 && _index6 + 1 == colum.row.game_type.substr(-1) && colum.row.name == "小") {
                return "themeColorhalf-row";
              }

              if (_zm_upopennumber[_index6].num % 2 !== 0 && _index6 + 1 == colum.row.game_type.substr(-1) && colum.row.name == "单") {
                return "themeColorhalf-row";
              }

              if (_zm_upopennumber[_index6].num % 2 == 0 && _index6 + 1 == colum.row.game_type.substr(-1) && colum.row.name == "双") {
                return "themeColorhalf-row";
              }

              if (parseInt(_zm_upopennumber[_index6].num / 10) + parseInt(_zm_upopennumber[_index6].num % 10) >= 7 && _index6 + 1 == colum.row.game_type.substr(-1) && colum.row.name == "合大") {
                return "themeColorhalf-row";
              }

              if (parseInt(_zm_upopennumber[_index6].num / 10) + parseInt(_zm_upopennumber[_index6].num % 10) <= 6 && _index6 + 1 == colum.row.game_type.substr(-1) && colum.row.name == "合小") {
                return "themeColorhalf-row";
              }

              if ((parseInt(_zm_upopennumber[_index6].num / 10) + parseInt(_zm_upopennumber[_index6].num % 10)) % 2 == 0 && _index6 + 1 == colum.row.game_type.substr(-1) && colum.row.name == "合双") {
                return "themeColorhalf-row";
              }

              if ((parseInt(_zm_upopennumber[_index6].num / 10) + parseInt(_zm_upopennumber[_index6].num % 10)) % 2 !== 0 && _index6 + 1 == colum.row.game_type.substr(-1) && colum.row.name == "合单") {
                return "themeColorhalf-row";
              }

              if (_zm_upopennumber[_index6].num % 10 <= 4 && _index6 + 1 == colum.row.game_type.substr(-1) && colum.row.name == "尾小") {
                return "themeColorhalf-row";
              }

              if (_zm_upopennumber[_index6].num % 10 >= 5 && _index6 + 1 == colum.row.game_type.substr(-1) && colum.row.name == "尾大") {
                return "themeColorhalf-row";
              }
            }

            if (_zm_upopennumber[_index6].color == "#f56c6c" && _index6 + 1 == colum.row.game_type.substr(-1) && colum.row.name == "红波") {
              return "themeColorhalf-row";
            }

            if (_zm_upopennumber[_index6].color == "#409eff" && _index6 + 1 == colum.row.game_type.substr(-1) && colum.row.name == "蓝波") {
              return "themeColorhalf-row";
            }

            if (_zm_upopennumber[_index6].color == "#67C23A" && _index6 + 1 == colum.row.game_type.substr(-1) && colum.row.name == "绿波") {
              return "themeColorhalf-row";
            }
          }
        }

        if (this.title == "生肖/尾数量") {
          if (colum.row.game_type == "生肖量") {
            var sxlist = [];
            upopennumber.forEach(function (item) {
              sxlist.push(item.sx);
            });

            var _list = _toConsumableArray(new Set(sxlist)); // console.log(sxlist,list);


            if (_list.length == colum.row.namenum) {
              return "themeColorhalf-row";
            }
          }

          if (colum.row.game_type == "尾数量") {
            var wslist = [];

            for (var _index7 = 0; _index7 < upopennumber.length; _index7++) {
              wslist.push(upopennumber[_index7].num.toString().slice(-1));
            }

            var _list2 = _toConsumableArray(new Set(wslist));

            if (_list2.length == colum.row.namenum) {
              return "themeColorhalf-row";
            }
          }
        }
      }

      if (this.game_info_data.stop_time == "00:00:00") {
        return "gray-row";
      }

      return "";
    },
    change_zupai_type: function change_zupai_type(value) {
      this.$emit("change_zupai_type", value);
    },
    //数组中相同值的个数
    // getRepeatNum(arr) {
    //     return arr.reduce(function (prev, next) {
    //         prev[next] = (prev[next] + 1) || 1;
    //         return prev;
    //     }, {});
    // },
    change_play: function change_play(name) {
      var t = name;

      if (t == "特码") {
        t = "特码A";
      }

      if (t == "正特1-6") {
        t = "正码/正特";
      }

      if (t == "正码") {
        t = "正码/正特";
      }

      if (t == "生肖量" || t == "尾数量") {
        t = "生肖/尾数量";
      }

      if (t == "连肖不中") {
        t = "连肖";
      }

      if (t == "尾数不中") {
        t = "尾数";
      }

      if (t == "尾连不中") {
        t = "尾连";
      }

      if (t == "一肖不中") {
        t = "一肖";
      }

      this.$emit("change_play", t);
    },
    sub_bh: function sub_bh() {
      var _this = this;

      // console.log(this.bh.row);
      if (this.bh.row.szsz_amount) {
        if (this.bh.je_value * 1 > this.bh.row.szsz_amount[1] * 1) {
          return this.$message.error("补货金额不能大于下注额");
        }
      } else {
        if (this.bh.je_value * 1 > this.bh.row.oc_bill * 1) {
          return this.$message.error("补货金额不能大于下注额");
        }
      }

      if (this.bh.row.szsz_amount != undefined && this.bh.row.szsz_amount[4] != undefined) {
        if (this.bh.je_value * 1 > this.bh.row.szsz_amount[4] * -1) {
          return this.$message.error("补货金额不能大于正亏损");
        }
      }

      if (this.bh.row.profit_bill) {
        if (this.bh.je_value * 1 > this.bh.row.profit_bill * -1) {
          return this.$message.error("补货金额不能大于正亏损");
        }
      }

      if (this.bh.je_value != "" && this.bh.je_value != 0 && this.bh.je_value * 1 < 1) {
        return this.$message.error("补货金额不能小于1");
      }

      var obj = {
        user_id: this.$store.state.userinfo.user_id,
        play_id: this.game_info_data.gid,
        level_two: this.bh.row.level_two,
        level_three: this.bh.row.level_three,
        level_four: this.bh.row.level_four,
        place: this.bh.row.place,
        ball: this.bh.row.ball || this.bh.row.name,
        qnum: this.game_info_data.nn,
        is_manlet: this.is_manlet,
        control_let: this.bh.je_value,
        rate: this.bh.row.pl || this.bh.row.list_bonus,
        rebate_level: this.bh.row.rebate_level || 0,
        oc_bill_s: this.bh.row.szsz_amount ? this.bh.row.szsz_amount[this.bh.row.szsz_amount.length - 1] : this.bh.row.oc_bill,
        oc_bill_tm: "",
        is_update: "0",
        pk: this.pk,
        title: this.bh.row.title == undefined ? this.bh.row.game_type : this.bh.row.title,
        num: this.bh.row.num == undefined ? 1 : this.bh.row.num,
        periods_id: this.game_info_data.p_id
      }; // console.log(this.title, this.balls);
      // console.log(obj.oc_bill_s, obj.oc_bill_tm);

      if (this.balls == "tm" && !Number.isNaN(Number(this.bh.row.name))) {
        if (this.bh.row.szsz_amount_tmb != undefined) {
          if (this.bh.row.szsz_amount_tmb[this.bh.row.szsz_amount_tmb.length - 1] == "" || this.bh.row.szsz_amount_tmb[this.bh.row.szsz_amount_tmb.length - 1] == "0") {
            obj.oc_bill_tm = 0;
          } else {
            obj.oc_bill_tm = this.bh.row.szsz_amount_tmb[this.bh.row.szsz_amount_tmb.length - 1];
          }
        } else {
          obj.oc_bill_tm = 0;
        }
      }

      if (this.title == "特码A+B" || this.title == "特码A" || this.title == "尾数") {
        obj.place = 1;
      }

      if (this.title == "特码B") {
        obj.place = 24;
      }

      if (this.title == "连码" || this.title == "连肖" || this.title == "自选不中" || this.title == "六肖一中" || this.title == "尾连") {
        obj.oc_bill_s = this.bh.row.occupy_bill_s;
      } //正特1-6这几个补货的place是3-8


      if (this.balls.includes("ztm")) {
        obj.place = this.balls.substr(-1) * 1 + 2;
      }

      this.axios.post("userlet/userLet", obj).then(function (result) {
        if (result.data.shortMessage.includes("是否确认补货")) {
          _this.$confirm(result.data.shortMessage, "提示", {
            customClass: "myMessageBox",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function () {
            obj.is_update = "1";

            _this.axios.post("userlet/userLet", obj).then(function (result) {
              if (result.data.status == 200) {
                _this.$message.success(result.data.shortMessage);

                _this.bh.show = false;
              }
            });
          }).catch(function () {
            obj.is_update = "0";

            _this.$message({
              type: "info",
              message: "已取消操作"
            });
          });
        } else {
          _this.$message.success(result.data.shortMessage);

          _this.bh.show = false;
        }
      });
    },
    show_bh: function show_bh(row, event) {
      // console.log(row, event, this.is_manlet);
      // console.log(row,this.game_info_data);
      this.bh.show = false;

      if (row.yk < 0 || row.profit_bill < 0) {
        if (this.is_manlet == "1" || this.is_manlet == "0") {
          this.bh.row = row;
          this.bh.pl_value = row.pl || row.list_bonus;
          this.bh.je_value = "";
          var width = document.body.clientWidth - event.clientX; // console.log(event.clientX, document.body.clientWidth);

          if (width < 140) {
            this.$refs["bh_popver"].style.left = event.pageX - 140 + "px";
          } else {
            this.$refs["bh_popver"].style.left = event.pageX + "px";
          }

          this.$refs["bh_popver"].style.top = event.pageY + "px";
          this.bh.show = true;
          this.$refs["bhje_input"].focus();
        }
      }
    },
    get_zdmx_data: function get_zdmx_data(row) {
      // console.log(row);
      if (row.szsz_amount && row.szsz_amount[1] != "" || row.oc_bill != 0) {
        this.$emit("get_zdmx_data", row);
      }
    },
    sub_change_pl: function sub_change_pl() {
      if (this.pl_popver.type == "1") {
        this.$emit("change_pl", this.pl_popver);
      } else {
        this.$emit("change_pl", this.pl_popver, "zupai");
      }
    },
    show_pl_popver: function show_pl_popver(row, event) {
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";

      if (this.$store.state.userinfo.group_id >= 4 || this.$store.state.userinfo.trader_status == 0) {
        return false;
      } // console.log(row, event);


      this.pl_popver.show = false;

      if (type == "") {
        //单码
        if (event == "plus" || event == "minus") {
          this.pl_popver.row = row;
          this.pl_popver.old_pl = row.pl;
          this.pl_popver.pl = row.pl;
          this.pl_popver.pl_change_value = "";
          this.pl_popver.plusminus = event;
          this.$emit("change_pl", this.pl_popver);
        } else {
          this.pl_popver.row = row;
          this.pl_popver.value = row.pl;
          this.pl_popver.old_pl = row.pl;
          this.pl_popver.plusminus = "";
          this.pl_popver.pl_change_value = Number(this.pl_popver.old_pl);
          this.$refs["pl_popver"].style.left = event.pageX + "px";
          this.$refs["pl_popver"].style.top = event.pageY + "px";
          this.pl_popver.show = true;
        }
      } else {
        //组排
        this.pl_popver.type = "2";

        if (event == "plus" || event == "minus") {
          this.pl_popver.row = row;
          this.pl_popver.old_pl = row.list_bonus;
          this.pl_popver.pl = row.list_bonus;
          this.pl_popver.pl_change_value = "";
          this.pl_popver.plusminus = event; // console.log(this.pl_popver);

          this.$emit("change_pl", this.pl_popver, "zupai");
        } else {
          this.pl_popver.row = row;
          this.pl_popver.value = row.list_bonus;
          this.pl_popver.old_pl = row.list_bonus;
          this.pl_popver.plusminus = "";
          this.pl_popver.pl_change_value = Number(this.pl_popver.old_pl);
          this.$refs["pl_popver"].style.left = event.pageX - 80 + "px";
          this.$refs["pl_popver"].style.top = event.pageY + "px";
          this.pl_popver.show = true;
        }
      }
    },
    //初始小玩法
    int_submenu: function int_submenu() {
      if (this.is_int_submenu) {
        if (this.title == "正码/正特") {
          this.zmzt_submenu.forEach(function (sub) {
            sub.class = "theme-half-bg";
          });
          this.zmzt_submenu[0].class = "theme-bg";
        }

        if (this.title == "一肖") {
          this.yx_tabs.forEach(function (sub) {
            sub.class = "theme-half-bg";
          });
          this.yx_tabs[0].class = "theme-bg";
        }

        if (this.title == "尾数") {
          this.ws_tabs.forEach(function (sub) {
            sub.class = "theme-half-bg";
          });
          this.ws_tabs[0].class = "theme-bg";
        }

        if (this.title == "连码") {
          this.zupai_title = "三全中";
          this.lm_submenu_checked = "5_1_0";
        }

        if (this.title == "自选不中") {
          this.zupai_title = "五不中";
          this.zxbz_submenu_checked = "8_0_0";
        }

        if (this.title == "连肖") {
          this.zupai_title = "连肖二肖";
          this.lx_tabs.forEach(function (sub) {
            sub.class = "theme-half-bg";
          });
          this.lx_tabs[0].class = "theme-bg";
          this.change_tabs({
            title: "中"
          });
        }

        if (this.title == "六肖一中") {
          this.zupai_title = "六肖一中";
        }

        if (this.title == "尾连") {
          this.zupai_title = "二尾连";
          this.wl_tabs.forEach(function (sub) {
            sub.class = "theme-half-bg";
          });
          this.wl_tabs[0].class = "theme-bg";
          this.change_tabs({
            title: "中"
          });
        }
      }
    },
    //切换小tabs
    change_tabs: function change_tabs(tabs) {
      // console.log(tabs);
      var submenu = {};

      if (this.title == "连肖") {
        this.lx_tabs.forEach(function (sub) {
          sub.class = "theme-half-bg";

          if (tabs.title == sub.title) {
            sub.class = "theme-bg";
          }
        });

        if (tabs.title == "中") {
          this.lx_submenu = [{
            title: "连肖二肖",
            link: "trader/index?ball=lx2",
            key: "9_0",
            szsz_amount: ["", "", "", "", "", ""]
          }, {
            title: "连肖三肖",
            link: "trader/index?ball=lx3",
            key: "9_1",
            szsz_amount: ["", "", "", "", "", ""]
          }, {
            title: "连肖四肖",
            link: "trader/index?ball=lx4",
            key: "9_2",
            szsz_amount: ["", "", "", "", "", ""]
          }, {
            title: "连肖五肖",
            link: "trader/index?ball=lx5",
            key: "9_3",
            szsz_amount: ["", "", "", "", "", ""]
          }];
          this.lx_submenu_checked = "9_0";
        } else {
          this.lx_submenu = [{
            title: "二肖连不中",
            link: "trader/index?ball=lx2bz",
            key: "18_0",
            szsz_amount: ["", "", "", "", "", ""]
          }, {
            title: "三肖连不中",
            link: "trader/index?ball=lx3bz",
            key: "18_1",
            szsz_amount: ["", "", "", "", "", ""]
          }, {
            title: "四肖连不中",
            link: "trader/index?ball=lx4bz",
            key: "18_2",
            szsz_amount: ["", "", "", "", "", ""]
          }, {
            title: "五肖连不中",
            link: "trader/index?ball=lx5bz",
            key: "18_3",
            szsz_amount: ["", "", "", "", "", ""]
          }];
          this.lx_submenu_checked = "18_0";
        }

        submenu = this.lx_submenu[0];
      }

      if (this.title == "尾连") {
        this.wl_tabs.forEach(function (sub) {
          sub.class = "theme-half-bg";

          if (tabs.title == sub.title) {
            sub.class = "theme-bg";
          }
        });

        if (tabs.title == "中") {
          this.wl_submenu = [{
            title: "二尾连",
            link: "trader/index?ball=wl",
            key: "12_0",
            szsz_amount: ["", "", "", "", "", ""]
          }, {
            title: "三尾连",
            link: "trader/index?ball=wl1",
            key: "12_1",
            szsz_amount: ["", "", "", "", "", ""]
          }, {
            title: "四尾连",
            link: "trader/index?ball=wl2",
            key: "12_2",
            szsz_amount: ["", "", "", "", "", ""]
          }, {
            title: "五尾连",
            link: "trader/index?ball=wl3",
            key: "12_3",
            szsz_amount: ["", "", "", "", "", ""]
          }];
          this.wl_submenu_checked = "12_0";
        } else {
          this.wl_submenu = [{
            title: "二尾连不中",
            link: "trader/index?ball=wlbz",
            key: "19_0",
            szsz_amount: ["", "", "", "", "", ""]
          }, {
            title: "三尾连不中",
            link: "trader/index?ball=wl1bz",
            key: "19_1",
            szsz_amount: ["", "", "", "", "", ""]
          }, {
            title: "四尾连不中",
            link: "trader/index?ball=wl2bz",
            key: "19_2",
            szsz_amount: ["", "", "", "", "", ""]
          }, {
            title: "五尾连不中",
            link: "trader/index?ball=wl3bz",
            key: "19_3",
            szsz_amount: ["", "", "", "", "", ""]
          }];
          this.wl_submenu_checked = "19_0";
        }

        submenu = this.wl_submenu[0];
      }

      this.change_submenu(submenu);
    },
    //切换小玩法
    change_submenu: function change_submenu(item) {
      if (this.title == "正码/正特") {
        this.zmzt_submenu.forEach(function (sub) {
          sub.class = "theme-half-bg";

          if (item.title == sub.title) {
            sub.class = "theme-bg";
          }
        });
      }

      if (this.title == "连码" || this.title == "自选不中" || this.title == "连肖" || this.title == "六肖一中" || this.title == "尾连") {
        this.zupai_title = item.title;
        this.pl_popver.show = false;
        this.zupai.page = 1;
      }

      if (this.title == "一肖") {
        this.yx_tabs.forEach(function (sub) {
          sub.class = "theme-half-bg";

          if (item.title == sub.title) {
            sub.class = "theme-bg";
          }
        });
      }

      if (this.title == "尾数") {
        this.ws_tabs.forEach(function (sub) {
          sub.class = "theme-half-bg";

          if (item.title == sub.title) {
            sub.class = "theme-bg";
          }
        });
      }

      this.is_int_submenu = false;
      this.$emit("change_submenu", item);
    },
    //盈亏正负排序设置
    set_kuishun_pailie: function set_kuishun_pailie() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "fu";
      var t = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "tm_px_ab";

      // console.log(this.$refs[t]);
      if (type == "fu") {
        this.kuisun_fuzhi_class = "theme-bg white-text";
        this.kuisun_zhengzhi_class = "theme-half-bg black-text";
        this.$refs[t].sort("szsz_amount[4]", "ascending");
      } else {
        this.kuisun_zhengzhi_class = "theme-bg white-text";
        this.kuisun_fuzhi_class = "theme-half-bg black-text";
        this.$refs[t].sort("szsz_amount[4]", "descending");
      }
    },
    proc: function proc(data) {
      var _this2 = this;

      // console.log(data);
      this.sum_bet_balance = data.sum_bet_balance;
      var play_odds = data.play_odds;
      var szsz_amount = {};

      if (data.szsz_amount) {
        szsz_amount = data.szsz_amount;
      } else {
        szsz_amount = {};
        data.szsz_amount_tm2 = {};
        data.szsz_amount_tmb = {};
      } // console.log(szsz_amount);


      var yk = [];
      var list_names = []; // console.log(this.title);

      if (this.title == "特码A+B") {
        data.play_odds_tm2.credit_list_lm.forEach(function (item) {
          play_odds.push(item);
        }); // console.log(data);

        var _loop = function _loop(key) {
          _this2.tmAB_lm.forEach(function (item) {
            if (key == item.id) {
              szsz_amount[key] = data.szsz_amount_tm2.oddis_arr_lm[key];
            }
          });
        };

        for (var key in data.szsz_amount_tm2.oddis_arr_lm) {
          _loop(key);
        }

        data.play_odds_tm2.credit_list_sb.forEach(function (item) {
          play_odds.push(item);
        });

        var _loop2 = function _loop2(_key) {
          _this2.tmAB_sb.forEach(function (item) {
            if (_key == item.id) {
              szsz_amount[_key] = data.szsz_amount_tm2.oddis_arr_sb[_key];
            }
          });
        };

        for (var _key in data.szsz_amount_tm2.oddis_arr_sb) {
          _loop2(_key);
        }

        list_names = ["tmAB_1_25", "tmAB_26_49", "tmAB_lm", "tmAB_sb", "tmpl"];
      }

      if (this.title == "特码A") {
        data.play_odds_tm2.credit_list_lm.forEach(function (item) {
          play_odds.push(item);
        });

        var _loop3 = function _loop3(_key2) {
          _this2.tmA_lm.forEach(function (item) {
            if (_key2 == item.id) {
              szsz_amount[_key2] = data.szsz_amount_tm2.oddis_arr_lm[_key2];
            }
          });
        };

        for (var _key2 in data.szsz_amount_tm2.oddis_arr_lm) {
          _loop3(_key2);
        }

        data.play_odds_tm2.credit_list_sb.forEach(function (item) {
          play_odds.push(item);
        });

        var _loop4 = function _loop4(_key3) {
          _this2.tmA_sb.forEach(function (item) {
            if (_key3 == item.id) {
              szsz_amount[_key3] = data.szsz_amount_tm2.oddis_arr_sb[_key3];
            }
          });
        };

        for (var _key3 in data.szsz_amount_tm2.oddis_arr_sb) {
          _loop4(_key3);
        }

        list_names = ["tmA_1_25", "tmA_26_49", "tmA_lm", "tmA_sb", "tmpl"];
      }

      if (this.title == "特码B") {
        data.play_odds_tm2.credit_list_lm.forEach(function (item) {
          play_odds.push(item);
        });

        var _loop5 = function _loop5(_key4) {
          _this2.tmB_lm.forEach(function (item) {
            if (_key4 == item.id) {
              szsz_amount[_key4] = data.szsz_amount_tm2.oddis_arr_lm[_key4];
            }
          });
        };

        for (var _key4 in data.szsz_amount_tm2.oddis_arr_lm) {
          _loop5(_key4);
        }

        data.play_odds_tm2.credit_list_sb.forEach(function (item) {
          play_odds.push(item);
        });

        var _loop6 = function _loop6(_key5) {
          _this2.tmB_sb.forEach(function (item) {
            if (_key5 == item.id) {
              szsz_amount[_key5] = data.szsz_amount_tm2.oddis_arr_sb[_key5];
            }
          });
        };

        for (var _key5 in data.szsz_amount_tm2.oddis_arr_sb) {
          _loop6(_key5);
        }

        list_names = ["tmB_1_25", "tmB_26_49", "tmB_lm", "tmB_sb", "tmpl"];
      }

      if (this.title == "色波") {
        list_names = ["sb_ds", "sb_dx", "sb"];
      }

      if (this.title == "特肖") {
        list_names = ["tx"];
      }

      if (this.title == "正码/正特") {
        list_names = ["zmzt_1_25", "zmzt_26_49", "zmztpl"];
      }

      if (this.title == "正码1-6") {
        list_names = ["zm1", "zm2", "zm3", "zm4", "zm5", "zm6"];
      }

      if (this.title == "一肖") {
        list_names = ["yx"];
      }

      if (this.title == "两面") {
        list_names = ["lianmian_t", "lianmian_h", "lianmian_z", "lianmian_w"];
      }

      if (this.title == "尾数") {
        list_names = ["ws"];
      }

      if (this.title == "生肖/尾数量") {
        list_names = ["sxl", "wsl"];
      } //处理小玩法累计数据


      if (this.title == "连码") {
        var _loop7 = function _loop7(_key6) {
          _this2.lm_submenu.forEach(function (item) {
            item.szsz_amount = ["", "", "", "", "", ""]; // console.log(item.key);

            if (_key6 == item.key) {
              item.szsz_amount = szsz_amount[_key6];
            }
          });
        };

        // console.log(szsz_amount);
        for (var _key6 in szsz_amount) {
          _loop7(_key6);
        }

        list_names = ["lm_1_16", "lm_17_32", "lm_33_49", "lm_submenu"];
        play_odds.forEach(function (element) {
          list_names.forEach(function (keyname) {
            _this2[keyname].forEach(function (item) {
              if (element.name == item.name) {
                item.id = element.id;
              }

              if ((_this2.balls == "lma" || _this2.balls == "lma3") && data.play_odds_tm2.credit_list_lma != undefined) {
                data.play_odds_tm2.credit_list_lma.forEach(function (e) {
                  if (e.name == item.name) {
                    e.newid = e.id;
                    e.pl *= 1;
                    item.otherdata = e;
                  }
                });
              }
            });
          });
        });
      }

      if (this.title == "自选不中") {
        this.zxbz_submenu.forEach(function (item) {
          item.szsz_amount = ["", "", "", "", "", ""];

          for (var _key7 in szsz_amount) {
            if (_key7 == item.key) {
              item.szsz_amount = szsz_amount[_key7].split(",");
            }
          }
        });
        list_names = ["zxbz_1_16", "zxbz_17_32", "zxbz_33_49", "zxbz_submenu"];
        play_odds.forEach(function (element) {
          list_names.forEach(function (keyname) {
            _this2[keyname].forEach(function (item) {
              if (element.name == item.name) {
                item.id = element.id;
              }
            });
          });
        });
      }

      if (this.title == "连肖") {
        this.lx_submenu.forEach(function (item) {
          item.szsz_amount = ["", "", "", "", "", ""];

          for (var _key8 in szsz_amount) {
            if (_key8 == item.key) {
              item.szsz_amount = szsz_amount[_key8].split(",");
            }
          }
        });
        list_names = ["lx", "lx_submenu"];
        play_odds.forEach(function (element) {
          list_names.forEach(function (keyname) {
            _this2[keyname].forEach(function (item) {
              if (element.name == item.name) {
                item.id = element.id;
              }
            });
          });
        });
      }

      if (this.title == "六肖一中") {
        this.lxyz_submenu.forEach(function (item) {
          item.szsz_amount = ["", "", "", "", "", ""];

          for (var _key9 in szsz_amount) {
            if (_key9 == item.key) {
              item.szsz_amount = szsz_amount[_key9].split(",");
            }
          }
        });
        list_names = ["lxyz", "lxyz_submenu"];
      }

      if (this.title == "尾连") {
        this.wl_submenu.forEach(function (item) {
          item.szsz_amount = ["", "", "", "", "", ""];

          for (var _key10 in szsz_amount) {
            if (_key10 == item.key) {
              item.szsz_amount = szsz_amount[_key10].split(",");
            }
          }
        });
        list_names = ["wl", "wl_submenu"];
        play_odds.forEach(function (element) {
          list_names.forEach(function (keyname) {
            _this2[keyname].forEach(function (item) {
              if (element.name == item.name) {
                item.id = element.id;
              }
            });
          });
        });
      } //处理小项


      play_odds.forEach(function (element) {
        list_names.forEach(function (keyname) {
          _this2[keyname].forEach(function (item) {
            if (keyname == "zm1" || keyname == "zm2" || keyname == "zm3" || keyname == "zm4" || keyname == "zm5" || keyname == "zm6") {
              if (element.id == item.id) {
                item.is_open = element.is_open;
                item.maxpl = element.maxpl * 1;
                item.minpl = element.minpl * 1;
                item.pl = element.pl * 1;
                item.level_two = element.level_two;
                item.level_three = element.level_three;
                item.level_four = element.level_four;
                item.control_let = element.control_let;
                item.newid = element.id;
                item.szsz_amount = ["", "", "", "", "", "", ""];
                item.yk = 0;
              }
            } else {
              if (element.id == item.id || element.name == item.name || keyname == "ws" && element.name.includes(item.namenum)) {
                item.is_open = element.is_open;
                item.maxpl = element.maxpl * 1;
                item.minpl = element.minpl * 1;
                item.pl = element.pl * 1;
                item.level_two = element.level_two;
                item.level_three = element.level_three;
                item.level_four = element.level_four;
                item.control_let = element.control_let;
                item.newid = element.id;
                item.szsz_amount = ["", "", "", "", "", "", ""];
                item.yk = 0; // if (this.balls == 'lma' || this.balls == 'lma3') {
                //     item.otherdata = element.otherdata * 1;
                // }
              }
            }
          });
        });
      }); //处理小项注额盈亏

      var _loop8 = function _loop8(_key11) {
        // console.log(key);
        list_names.forEach(function (keyname) {
          _this2[keyname].forEach(function (item) {
            // console.log(item);
            if (_key11 == item.id || _key11 == item.newid || _key11 == item.key) {
              item.szsz_amount = szsz_amount[_key11].split(","); // console.log(item.szsz_amount);

              if (_this2.balls == "tm") {
                if (data.szsz_amount_tmb[_key11] != undefined) {
                  if (!Array.isArray(data.szsz_amount_tmb[_key11]) && !data.szsz_amount_tmb[_key11].length == 0) {
                    if (data.szsz_amount_tmb[_key11].split != undefined) {
                      item.szsz_amount_tmb = data.szsz_amount_tmb[_key11].split(",");
                    }
                  }
                }
              }

              if (keyname == "zmztpl") {
                item.type_zteb = 0;

                if (data.szsz_amount_ztb && data.szsz_amount_ztb[_key11] != undefined) {
                  if (!Array.isArray(data.szsz_amount_ztb[_key11]) && !data.szsz_amount_ztb[_key11].length == 0) {
                    if (data.szsz_amount_ztb[_key11].split != undefined) {
                      item.szsz_amount = data.szsz_amount_ztb[_key11].split(",");

                      if (!_this2.balls.includes("b")) {
                        item.type_zteb = 1;
                      }
                    }
                  }
                }
              }

              item.szsz_amount[4] *= 1;
              item.yk = item.szsz_amount[4];
            }

            yk.push(item.yk);
          });
        });
      };

      for (var _key11 in szsz_amount) {
        _loop8(_key11);
      }

      this.max_yl = yk.sort(function (a, b) {
        return b - a;
      })[0] || 0;
      this.max_ks = yk.sort(function (a, b) {
        return a - b;
      })[0] || 0;
    }
  }
};