import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";

/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-11 15:48:18
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2025-01-28 21:05:39
 * @FilePath: \super_admin\src\plugin\sx.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/**六合彩生肖计算 */
// type：生成那种情况下的生肖
//arr，每个元素带有num,sx属性
export function get_lhc_sx(type, arr) {
  var idx = 0;
  var sxArr = ['猴', '鸡', '狗', '猪', '鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊'];

  if (type == "开奖") {
    for (var i = 0; i < arr.length; i++) {
      idx = arr[i].num; //计算生肖排序

      var time = arr[i].lottery_open.split('-');

      if (new Date("".concat(time[0], "-").concat(time[1], "-").concat(time[2])).getTime() < 1738080000000) {
        switch (Number(idx)) {
          case 12:
          case 24:
          case 36:
          case 48:
            arr[i].sx = '蛇';
            break;

          case 11:
          case 23:
          case 35:
          case 47:
            arr[i].sx = '马';
            break;

          case 10:
          case 22:
          case 34:
          case 46:
            arr[i].sx = '羊';
            break;

          case 9:
          case 21:
          case 33:
          case 45:
            arr[i].sx = '猴';
            break;

          case 8:
          case 20:
          case 32:
          case 44:
            arr[i].sx = '鸡';
            break;

          case 7:
          case 19:
          case 31:
          case 43:
            arr[i].sx = '狗';
            break;

          case 6:
          case 18:
          case 30:
          case 42:
            arr[i].sx = '猪';
            break;

          case 5:
          case 17:
          case 29:
          case 41:
            arr[i].sx = '鼠';
            break;

          case 4:
          case 16:
          case 28:
          case 40:
            arr[i].sx = '牛';
            break;

          case 3:
          case 15:
          case 27:
          case 39:
            arr[i].sx = '虎';
            break;

          case 2:
          case 14:
          case 26:
          case 38:
            arr[i].sx = '兔';
            break;

          case 1:
          case 13:
          case 25:
          case 37:
          case 49:
            arr[i].sx = '龙';
            break;
        }
      } else {
        switch (Number(idx)) {
          case 12:
          case 24:
          case 36:
          case 48:
            arr[i].sx = '马';
            break;

          case 11:
          case 23:
          case 35:
          case 47:
            arr[i].sx = '羊';
            break;

          case 10:
          case 22:
          case 34:
          case 46:
            arr[i].sx = '猴';
            break;

          case 9:
          case 21:
          case 33:
          case 45:
            arr[i].sx = '鸡';
            break;

          case 8:
          case 20:
          case 32:
          case 44:
            arr[i].sx = '狗';
            break;

          case 7:
          case 19:
          case 31:
          case 43:
            arr[i].sx = '猪';
            break;

          case 6:
          case 18:
          case 30:
          case 42:
            arr[i].sx = '鼠';
            break;

          case 5:
          case 17:
          case 29:
          case 41:
            arr[i].sx = '牛';
            break;

          case 4:
          case 16:
          case 28:
          case 40:
            arr[i].sx = '虎';
            break;

          case 3:
          case 15:
          case 27:
          case 39:
            arr[i].sx = '兔';
            break;

          case 2:
          case 14:
          case 26:
          case 38:
            arr[i].sx = '龙';
            break;

          case 1:
          case 13:
          case 25:
          case 37:
          case 49:
            arr[i].sx = '蛇';
            break;
        }
      }
    }
  }

  return arr;
}