export default {
    pl_popver: {
        show: false,
        row: {},
        pl_value: "",
        pl_change_value: "",
        top: "",
        left: "",
        type: "1",
    },
    bh: {
        show: false,
        row: {},
        pl_value: "",
        je_value: "",
        rate: "",
        top: "",
        left: "",
    },
    sum_bet_balance: "", //总投注额
    max_ks: "", //最高亏损
    max_yl: "", //最高盈利
    kuisun_fuzhi_class: "theme-bg white-text",
    kuisun_zhengzhi_class: "theme-half-bg black-text",
    kuisun_obj: { prop: "szsz_amount[4]", order: "ascending" },
    zupai_title: "",
    is_int_submenu: true,
    zupai_type: "1",
    zupai_page: 1,
    // 特码A+B
    tmAB_1_25: [
        { id: 1, name: "1", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2, name: "2", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 3, name: "3", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 4, name: "4", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 5, name: "5", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 6, name: "6", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 7, name: "7", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 8, name: "8", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 9, name: "9", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 10, name: "10", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 11, name: "11", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 12, name: "12", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 13, name: "13", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 14, name: "14", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 15, name: "15", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 16, name: "16", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 17, name: "17", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 18, name: "18", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 19, name: "19", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 20, name: "20", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 21, name: "21", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 22, name: "22", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 23, name: "23", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 24, name: "24", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 25, name: "25", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    tmAB_26_49: [
        { id: 26, name: "26", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 27, name: "27", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 28, name: "28", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 29, name: "29", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 30, name: "30", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 31, name: "31", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 32, name: "32", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 33, name: "33", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 34, name: "34", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 35, name: "35", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 36, name: "36", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 37, name: "37", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 38, name: "38", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 39, name: "39", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 40, name: "40", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 41, name: "41", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 42, name: "42", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 43, name: "43", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 44, name: "44", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 45, name: "45", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 46, name: "46", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 47, name: "47", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 48, name: "48", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 49, name: "49", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    tmAB_lm: [
        { id: 126, name: "家禽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 109, name: "野兽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 110, name: "特大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 111, name: "特小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 112, name: "特单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 113, name: "特双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 114, name: "合大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 115, name: "合小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 116, name: "合单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 117, name: "合双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 118, name: "总大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 119, name: "总小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 120, name: "总单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 121, name: "总双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 122, name: "尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 123, name: "尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 124, name: "合尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 125, name: "合尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
    ],
    tmAB_sb: [
        { id: 74, name: "红波", color: "#fa3245", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 75, name: "蓝波", color: "#2ea9e3", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 76, name: "绿波", color: "#4fca66", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    //特码A
    tmA_1_25: [
        { id: 1, name: "1", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2, name: "2", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 3, name: "3", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 4, name: "4", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 5, name: "5", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 6, name: "6", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 7, name: "7", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 8, name: "8", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 9, name: "9", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 10, name: "10", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 11, name: "11", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 12, name: "12", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 13, name: "13", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 14, name: "14", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 15, name: "15", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 16, name: "16", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 17, name: "17", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 18, name: "18", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 19, name: "19", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 20, name: "20", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 21, name: "21", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 22, name: "22", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 23, name: "23", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 24, name: "24", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 25, name: "25", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    tmA_26_49: [
        { id: 26, name: "26", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 27, name: "27", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 28, name: "28", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 29, name: "29", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 30, name: "30", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 31, name: "31", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 32, name: "32", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 33, name: "33", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 34, name: "34", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 35, name: "35", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 36, name: "36", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 37, name: "37", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 38, name: "38", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 39, name: "39", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 40, name: "40", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 41, name: "41", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 42, name: "42", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 43, name: "43", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 44, name: "44", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 45, name: "45", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 46, name: "46", game_type: "特码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 47, name: "47", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 48, name: "48", game_type: "特码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 49, name: "49", game_type: "特码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    tmA_lm: [
        { id: 126, name: "家禽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 109, name: "野兽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 110, name: "特大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 111, name: "特小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 112, name: "特单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 113, name: "特双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 114, name: "合大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 115, name: "合小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 116, name: "合单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 117, name: "合双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 118, name: "总大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 119, name: "总小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 120, name: "总单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 121, name: "总双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 122, name: "尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 123, name: "尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 124, name: "合尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 125, name: "合尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
    ],
    tmA_sb: [
        { id: 74, name: "红波", color: "#fa3245", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 75, name: "蓝波", color: "#2ea9e3", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 76, name: "绿波", color: "#4fca66", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    //特码B
    tmB_1_25: [
        { id: 1700, name: "1", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1701, name: "2", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1702, name: "3", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1703, name: "4", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1704, name: "5", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1705, name: "6", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1706, name: "7", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1707, name: "8", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1708, name: "9", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1709, name: "10", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1710, name: "11", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1711, name: "12", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1712, name: "13", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1713, name: "14", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1714, name: "15", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1715, name: "16", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1716, name: "17", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1717, name: "18", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1718, name: "19", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1719, name: "20", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1720, name: "21", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1721, name: "22", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1722, name: "23", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1723, name: "24", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1724, name: "25", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    tmB_26_49: [
        { id: 1725, name: "26", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1726, name: "27", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1727, name: "28", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1728, name: "29", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1729, name: "30", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1730, name: "31", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1731, name: "32", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1732, name: "33", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1733, name: "34", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1734, name: "35", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1735, name: "36", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1736, name: "37", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1737, name: "38", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1738, name: "39", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1739, name: "40", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1740, name: "41", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1741, name: "42", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1742, name: "43", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1743, name: "44", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1744, name: "45", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1745, name: "46", game_type: "特码B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1746, name: "47", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1747, name: "48", game_type: "特码B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1748, name: "49", game_type: "特码B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    tmB_lm: [
        { id: 126, name: "家禽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 109, name: "野兽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 110, name: "特大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 111, name: "特小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 112, name: "特单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 113, name: "特双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 114, name: "合大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 115, name: "合小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 116, name: "合单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 117, name: "合双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 118, name: "总大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 119, name: "总小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 120, name: "总单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 121, name: "总双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 122, name: "尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 123, name: "尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 124, name: "合尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 125, name: "合尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
    ],
    tmB_sb: [
        { id: 74, name: "红波", color: "#fa3245", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 75, name: "蓝波", color: "#2ea9e3", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 76, name: "绿波", color: "#4fca66", game_type: "色波", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    tmpl: [
        { id: 1, name: "1", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2, name: "2", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 3, name: "3", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 4, name: "4", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 5, name: "5", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 6, name: "6", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 7, name: "7", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 8, name: "8", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 9, name: "9", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 10, name: "10", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 11, name: "11", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 12, name: "12", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 13, name: "13", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 14, name: "14", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 15, name: "15", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 16, name: "16", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 17, name: "17", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 18, name: "18", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 19, name: "19", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 20, name: "20", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 21, name: "21", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 22, name: "22", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 23, name: "23", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 24, name: "24", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 25, name: "25", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 26, name: "26", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 27, name: "27", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 28, name: "28", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 29, name: "29", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 30, name: "30", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 31, name: "31", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 32, name: "32", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 33, name: "33", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 34, name: "34", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 35, name: "35", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 36, name: "36", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 37, name: "37", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 38, name: "38", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 39, name: "39", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 40, name: "40", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 41, name: "41", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 42, name: "42", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 43, name: "43", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 44, name: "44", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 45, name: "45", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 46, name: "46", game_type: "特码A+B", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 47, name: "47", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 48, name: "48", game_type: "特码A+B", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 49, name: "49", game_type: "特码A+B", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    //色波
    sb: [
        { id: 74, name: "红波", game_type: "色波", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 75, name: "蓝波", game_type: "色波", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 76, name: "绿波", game_type: "色波", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    sb_ds: [
        {
            id: 64,
            name: "红单",
            game_type: "色波",
            nums: [1, 7, 13, 19, 23, 29, 35, 45],
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 65,
            name: "红双",
            game_type: "色波",
            nums: [2, 8, 12, 18, 24, 30, 34, 40, 46],
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 68,
            name: "蓝单",
            game_type: "色波",
            nums: [3, 9, 15, 25, 31, 37, 41, 47],
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 69,
            name: "蓝双",
            game_type: "色波",
            nums: [4, 10, 14, 20, 26, 36, 42, 48],
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 72,
            name: "绿单",
            game_type: "色波",
            nums: [5, 11, 17, 21, 27, 33, 39, 43, 49],
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 73,
            name: "绿双",
            game_type: "色波",
            nums: [6, 16, 22, 28, 32, 38, 44],
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
    ],
    sb_dx: [
        {
            id: 62,
            name: "红大",
            game_type: "色波",
            nums: [29, 30, 34, 35, 40, 45, 46],
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 63,
            name: "红小",
            game_type: "色波",
            nums: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24],
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 66,
            name: "蓝大",
            game_type: "色波",
            nums: [25, 26, 31, 36, 37, 41, 42, 47, 48],
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 67,
            name: "蓝小",
            game_type: "色波",
            nums: [3, 4, 9, 10, 14, 15, 20],
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 70,
            name: "绿大",
            game_type: "色波",
            nums: [27, 28, 32, 33, 38, 39, 43, 44, 49],
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 71,
            name: "绿小",
            game_type: "色波",
            nums: [5, 6, 11, 16, 17, 21, 22],
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
    ],
    //特肖
    tx: [
        {
            id: 56,
            name: "鼠",
            game_type: "特肖",
            nums: [
                { num: 6, color: "#4fca66" },
                { num: 18, color: "#fa3245" },
                { num: 30, color: "#fa3245" },
                { num: 42, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 57,
            name: "牛",
            game_type: "特肖",
            nums: [
                { num: 5, color: "#4fca66" },
                { num: 17, color: "#4fca66" },
                { num: 29, color: "#fa3245" },
                { num: 41, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 58,
            name: "虎",
            game_type: "特肖",
            nums: [
                { num: 4, color: "#2ea9e3" },
                { num: 16, color: "#4fca66" },
                { num: 28, color: "#4fca66" },
                { num: 40, color: "#fa3245" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 59,
            name: "兔",
            game_type: "特肖",
            nums: [
                { num: 3, color: "#2ea9e3" },
                { num: 15, color: "#2ea9e3" },
                { num: 27, color: "#4fca66" },
                { num: 39, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 60,
            name: "龙",
            game_type: "特肖",
            nums: [
                { num: 2, color: "#fa3245" },
                { num: 14, color: "#2ea9e3" },
                { num: 26, color: "#2ea9e3" },
                { num: 38, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 61,
            name: "蛇",
            game_type: "特肖",
            nums: [
                { num: 1, color: "#fa3245" },
                { num: 13, color: "#fa3245" },
                { num: 25, color: "#2ea9e3" },
                { num: 37, color: "#2ea9e3" },
                { num: 49, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 50,
            name: "马",
            game_type: "特肖",
            nums: [
                { num: 12, color: "#fa3245" },
                { num: 24, color: "#fa3245" },
                { num: 36, color: "#2ea9e3" },
                { num: 48, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 51,
            name: "羊",
            game_type: "特肖",
            nums: [
                { num: 11, color: "#4fca66" },
                { num: 23, color: "#fa3245" },
                { num: 35, color: "#fa3245" },
                { num: 47, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 52,
            name: "猴",
            game_type: "特肖",
            nums: [
                { num: 10, color: "#2ea9e3" },
                { num: 22, color: "#4fca66" },
                { num: 34, color: "#fa3245" },
                { num: 46, color: "#fa3245" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 53,
            name: "鸡",
            game_type: "特肖",
            nums: [
                { num: 9, color: "#2ea9e3" },
                { num: 21, color: "#4fca66" },
                { num: 33, color: "#4fca66" },
                { num: 45, color: "#fa3245" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 54,
            name: "狗",
            game_type: "特肖",
            nums: [
                { num: 8, color: "#fa3245" },
                { num: 20, color: "#2ea9e3" },
                { num: 32, color: "#4fca66" },
                { num: 44, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 55,
            name: "猪",
            game_type: "特肖",
            nums: [
                { num: 7, color: "#fa3245" },
                { num: 19, color: "#fa3245" },
                { num: 31, color: "#2ea9e3" },
                { num: 43, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
    ],
    //正码/正特
    zmzt_1_25: [
        { id: 201, name: "1", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 202, name: "2", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 203, name: "3", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 204, name: "4", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 205, name: "5", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 206, name: "6", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 207, name: "7", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 208, name: "8", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 209, name: "9", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 210, name: "10", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 211, name: "11", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 212, name: "12", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 213, name: "13", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 214, name: "14", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 215, name: "15", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 216, name: "16", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 217, name: "17", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 218, name: "18", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 219, name: "19", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 220, name: "20", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 221, name: "21", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 222, name: "22", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 223, name: "23", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 224, name: "24", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 225, name: "25", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    zmzt_26_49: [
        { id: 226, name: "26", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 227, name: "27", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 228, name: "28", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 229, name: "29", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 230, name: "30", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 231, name: "31", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 232, name: "32", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 233, name: "33", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 234, name: "34", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 235, name: "35", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 236, name: "36", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 237, name: "37", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 238, name: "38", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 239, name: "39", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 240, name: "40", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 241, name: "41", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 242, name: "42", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 243, name: "43", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 244, name: "44", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 245, name: "45", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 246, name: "46", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 247, name: "47", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 248, name: "48", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 249, name: "49", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    zmztpl: [
        { id: 201, name: "1", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 202, name: "2", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 203, name: "3", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 204, name: "4", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 205, name: "5", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 206, name: "6", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 207, name: "7", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 208, name: "8", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 209, name: "9", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 210, name: "10", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 211, name: "11", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 212, name: "12", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 213, name: "13", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 214, name: "14", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 215, name: "15", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 216, name: "16", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 217, name: "17", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 218, name: "18", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 219, name: "19", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 220, name: "20", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 221, name: "21", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 222, name: "22", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 223, name: "23", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 224, name: "24", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 225, name: "25", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 226, name: "26", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 227, name: "27", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 228, name: "28", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 229, name: "29", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 230, name: "30", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 231, name: "31", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 232, name: "32", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 233, name: "33", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 234, name: "34", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 235, name: "35", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 236, name: "36", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 237, name: "37", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 238, name: "38", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 239, name: "39", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 240, name: "40", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 241, name: "41", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 242, name: "42", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 243, name: "43", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 244, name: "44", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 245, name: "45", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 246, name: "46", game_type: "正码/正特", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 247, name: "47", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 248, name: "48", game_type: "正码/正特", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 249, name: "49", game_type: "正码/正特", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    zmzt_submenu: [
        {
            title: "正码",
            link: "trader/index?ball=zm",
            class: "theme-bg",
        },
        {
            title: "正特一",
            link: "trader/index?ball=ztm1",
            class: "theme-half-bg",
        },
        {
            title: "正特一B",
            link: "trader/index?ball=ztm1b",
            class: "theme-half-bg",
        },
        {
            title: "正特二",
            link: "trader/index?ball=ztm2",
            class: "theme-half-bg",
        },
        {
            title: "正特二B",
            link: "trader/index?ball=ztm2b",
            class: "theme-half-bg",
        },
        {
            title: "正特三",
            link: "trader/index?ball=ztm3",
            class: "theme-half-bg",
        },
        {
            title: "正特三B",
            link: "trader/index?ball=ztm3b",
            class: "theme-half-bg",
        },
        {
            title: "正特四",
            link: "trader/index?ball=ztm4",
            class: "theme-half-bg",
        },
        {
            title: "正特四B",
            link: "trader/index?ball=ztm4b",
            class: "theme-half-bg",
        },
        {
            title: "正特五",
            link: "trader/index?ball=ztm5",
            class: "theme-half-bg",
        },
        {
            title: "正特五B",
            link: "trader/index?ball=ztm5b",
            class: "theme-half-bg",
        },
        {
            title: "正特六",
            link: "trader/index?ball=ztm6",
            class: "theme-half-bg",
        },
        {
            title: "正特六B",
            link: "trader/index?ball=ztm6b",
            class: "theme-half-bg",
        },
    ],
    //正码1-6
    zm1: [
        { id: 1840, name: "大", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1841, name: "小", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 1, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1842, name: "单", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 2, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1843, name: "双", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 3, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1844, name: "合大", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 4, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1845, name: "合小", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 5, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1846, name: "合单", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 6, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1847, name: "合双", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 7, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1848, name: "尾大", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 8, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1849, name: "尾小", game_type: "正码1", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 9, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1850, name: "红波", game_type: "正码1", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 10, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1851, name: "绿波", game_type: "正码1", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 11, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1852, name: "蓝波", game_type: "正码1", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 12, level_three: 0, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    zm2: [
        { id: 1853, name: "大", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1854, name: "小", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 1, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1855, name: "单", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 2, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1856, name: "双", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 3, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1857, name: "合大", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 4, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1858, name: "合小", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 5, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1859, name: "合单", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 6, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1860, name: "合双", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 7, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1861, name: "尾大", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 8, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1862, name: "尾小", game_type: "正码2", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 9, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1863, name: "红波", game_type: "正码2", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 10, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1864, name: "绿波", game_type: "正码2", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 11, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1865, name: "蓝波", game_type: "正码2", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 12, level_three: 1, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    zm3: [
        { id: 1866, name: "大", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1867, name: "小", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 1, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1868, name: "单", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 2, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1869, name: "双", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 3, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1870, name: "合大", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 4, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1871, name: "合小", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 5, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1872, name: "合单", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 6, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1873, name: "合双", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 7, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1874, name: "尾大", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 8, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1875, name: "尾小", game_type: "正码3", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 9, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1876, name: "红波", game_type: "正码3", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 10, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1877, name: "绿波", game_type: "正码3", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 11, level_three: 2, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1879, name: "蓝波", game_type: "正码3", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 12, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    zm4: [
        { id: 1880, name: "大", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1881, name: "小", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 1, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1882, name: "单", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 2, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1883, name: "双", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 3, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1884, name: "合大", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 4, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1885, name: "合小", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 5, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1886, name: "合单", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 6, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1887, name: "合双", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 7, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1888, name: "尾大", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 8, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1889, name: "尾小", game_type: "正码4", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 9, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1890, name: "红波", game_type: "正码4", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 10, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1891, name: "绿波", game_type: "正码4", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 11, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1892, name: "蓝波", game_type: "正码4", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 12, level_three: 3, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    zm5: [
        { id: 1893, name: "大", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1894, name: "小", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 1, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1895, name: "单", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 2, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1896, name: "双", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 3, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1897, name: "合大", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 4, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1898, name: "合小", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 5, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1899, name: "合单", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 6, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1900, name: "合双", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 7, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1901, name: "尾大", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 8, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1902, name: "尾小", game_type: "正码5", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 9, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1903, name: "红波", game_type: "正码5", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 10, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1904, name: "绿波", game_type: "正码5", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 11, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1905, name: "蓝波", game_type: "正码5", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 12, level_three: 4, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    zm6: [
        { id: 1906, name: "大", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1907, name: "小", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 1, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1908, name: "单", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 2, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1909, name: "双", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 3, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1910, name: "合大", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 4, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1911, name: "合小", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 5, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1912, name: "合单", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 6, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1913, name: "合双", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 7, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 1914, name: "尾大", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 8, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1915, name: "尾小", game_type: "正码6", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 9, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1916, name: "红波", game_type: "正码6", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 10, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1917, name: "绿波", game_type: "正码6", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 11, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1918, name: "蓝波", game_type: "正码6", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 12, level_three: 5, level_two: 16, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    //连码
    lm_submenu_checked: "5_1_0",
    lm_submenu: [
        {
            title: "三全中",
            link: "trader/index?ball=lma1",
            key: "5_1_0",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "三中二",
            link: "trader/index?ball=lma",
            key: "5_0_0",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "二全中",
            link: "trader/index?ball=lma2",
            key: "5_2_0",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "二中特",
            link: "trader/index?ball=lma3",
            key: "5_3_0",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "特串",
            link: "trader/index?ball=lma4",
            key: "5_4_0",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "四中一",
            link: "trader/index?ball=lma5",
            key: "5_5_0",
            szsz_amount: ["", "", "", "", "", ""],
        },
    ],
    lm_1_16: [
        {
            id: 1,
            name: "1",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 2,
            name: "2",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 3,
            name: "3",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 4,
            name: "4",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 5,
            name: "5",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 6,
            name: "6",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 7,
            name: "7",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 8,
            name: "8",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 9,
            name: "9",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 10,
            name: "10",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 11,
            name: "11",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 12,
            name: "12",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 13,
            name: "13",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 14,
            name: "14",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 15,
            name: "15",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 16,
            name: "16",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
    ],
    lm_17_32: [
        {
            id: 17,
            name: "17",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 18,
            name: "18",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 19,
            name: "19",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 20,
            name: "20",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 21,
            name: "21",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 22,
            name: "22",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 23,
            name: "23",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 24,
            name: "24",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 25,
            name: "25",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 26,
            name: "26",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 27,
            name: "27",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 28,
            name: "28",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 29,
            name: "29",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 30,
            name: "30",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 31,
            name: "31",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 32,
            name: "32",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
    ],
    lm_33_49: [
        {
            id: 33,
            name: "33",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 34,
            name: "34",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 35,
            name: "35",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 36,
            name: "36",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 37,
            name: "37",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 38,
            name: "38",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 39,
            name: "39",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 40,
            name: "40",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 41,
            name: "41",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 42,
            name: "42",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 43,
            name: "43",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 44,
            name: "44",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 45,
            name: "45",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 46,
            name: "46",
            game_type: "连码",
            color: "#fa3245",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 47,
            name: "47",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 48,
            name: "48",
            game_type: "连码",
            color: "#2ea9e3",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
        {
            id: 49,
            name: "49",
            game_type: "连码",
            color: "#4fca66",
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
            otherdata: { pl: "" },
        },
    ],
    //一肖
    yx_tabs: [
        {
            title: "中",
            link: "trader/index?ball=yx",
            key: "yx",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "不中",
            link: "trader/index?ball=yxbz",
            key: "yxbz",
            szsz_amount: ["", "", "", "", "", ""],
        },
    ],
    yx: [
        {
            id: 87,
            name: "鼠",
            game_type: "一肖",
            nums: [
                { num: 6, color: "#4fca66" },
                { num: 18, color: "#fa3245" },
                { num: 30, color: "#fa3245" },
                { num: 42, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,pl: "",level_four: 0,level_three: 0,level_two: 3,control_let: 0,place: "2",szsz_amount: ["", "", "", "", "", "", ""],},
        {
            id: 88,
            name: "牛",
            game_type: "一肖",
            nums: [
                { num: 5, color: "#4fca66" },
                { num: 17, color: "#4fca66" },
                { num: 29, color: "#fa3245" },
                { num: 41, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 89,
            name: "虎",
            game_type: "一肖",
            nums: [
                { num: 4, color: "#2ea9e3" },
                { num: 16, color: "#4fca66" },
                { num: 28, color: "#4fca66" },
                { num: 40, color: "#fa3245" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 90,
            name: "兔",
            game_type: "一肖",
            nums: [
                { num: 3, color: "#2ea9e3" },
                { num: 15, color: "#2ea9e3" },
                { num: 27, color: "#4fca66" },
                { num: 39, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 91,
            name: "龙",
            game_type: "一肖",
            nums: [
                { num: 2, color: "#fa3245" },
                { num: 14, color: "#2ea9e3" },
                { num: 26, color: "#2ea9e3" },
                { num: 38, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 92,
            name: "蛇",
            game_type: "一肖",
            nums: [
                { num: 1, color: "#fa3245" },
                { num: 13, color: "#fa3245" },
                { num: 25, color: "#2ea9e3" },
                { num: 37, color: "#2ea9e3" },
                { num: 49, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 81,
            name: "马",
            game_type: "一肖",
            nums: [
                { num: 12, color: "#fa3245" },
                { num: 24, color: "#fa3245" },
                { num: 36, color: "#2ea9e3" },
                { num: 48, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 82,
            name: "羊",
            game_type: "一肖",
            nums: [
                { num: 11, color: "#4fca66" },
                { num: 23, color: "#fa3245" },
                { num: 35, color: "#fa3245" },
                { num: 47, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 83,
            name: "猴",
            game_type: "一肖",
            nums: [
                { num: 10, color: "#2ea9e3" },
                { num: 22, color: "#4fca66" },
                { num: 34, color: "#fa3245" },
                { num: 46, color: "#fa3245" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 84,
            name: "鸡",
            game_type: "一肖",
            nums: [
                { num: 9, color: "#2ea9e3" },
                { num: 21, color: "#4fca66" },
                { num: 33, color: "#4fca66" },
                { num: 45, color: "#fa3245" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 85,
            name: "狗",
            game_type: "一肖",
            nums: [
                { num: 8, color: "#fa3245" },
                { num: 20, color: "#2ea9e3" },
                { num: 32, color: "#4fca66" },
                { num: 44, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 86,
            name: "猪",
            game_type: "一肖",
            nums: [
                { num: 7, color: "#fa3245" },
                { num: 19, color: "#fa3245" },
                { num: 31, color: "#2ea9e3" },
                { num: 43, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
    ],
    //自选不中
    zxbz_submenu_checked: "8_0_0",
    zxbz_submenu: [
        {
            title: "五不中",
            link: "trader/index?ball=bz5",
            key: "8_0_0",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "六不中",
            link: "trader/index?ball=bz6",
            key: "8_0_1",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "七不中",
            link: "trader/index?ball=bz7",
            key: "8_0_2",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "八不中",
            link: "trader/index?ball=bz8",
            key: "8_0_3",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "九不中",
            link: "trader/index?ball=bz9",
            key: "8_0_4",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "十不中",
            link: "trader/index?ball=bz10",
            key: "8_0_5",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "十一不中",
            link: "trader/index?ball=bz11",
            key: "8_0_6",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "十二不中",
            link: "trader/index?ball=bz12",
            key: "8_0_7",
            szsz_amount: ["", "", "", "", "", ""],
        },
    ],
    zxbz_1_16: [
        { id: 1, name: "1", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2, name: "2", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 3, name: "3", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 4, name: "4", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 5, name: "5", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 6, name: "6", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 7, name: "7", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 8, name: "8", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 9, name: "9", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 10, name: "10", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 11, name: "11", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 12, name: "12", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 13, name: "13", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 14, name: "14", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 15, name: "15", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 16, name: "16", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    zxbz_17_32: [
        { id: 17, name: "17", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 18, name: "18", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 19, name: "19", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 20, name: "20", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 21, name: "21", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 22, name: "22", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 23, name: "23", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 24, name: "24", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 25, name: "25", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 26, name: "26", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 27, name: "27", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 28, name: "28", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 29, name: "29", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 30, name: "30", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 31, name: "31", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 32, name: "32", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    zxbz_33_49: [
        { id: 33, name: "33", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 34, name: "34", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 35, name: "35", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 36, name: "36", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 37, name: "37", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 38, name: "38", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 39, name: "39", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 40, name: "40", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 41, name: "41", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 42, name: "42", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 43, name: "43", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 44, name: "44", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 45, name: "45", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 46, name: "46", game_type: "连码", color: "#fa3245", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 47, name: "47", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 48, name: "48", game_type: "连码", color: "#2ea9e3", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 49, name: "49", game_type: "连码", color: "#4fca66", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    //连肖
    lx_tabs: [
        {
            title: "中",
            link: "trader/index?ball=lx2",
            key: "lx",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "不中",
            link: "trader/index?ball=lx2bz",
            key: "lxbz",
            szsz_amount: ["", "", "", "", "", ""],
        },
    ],
    lx_submenu_checked: "9_0",
    lx_submenu: [
        {
            title: "连肖二肖",
            link: "trader/index?ball=lx2",
            key: "9_0",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "连肖三肖",
            link: "trader/index?ball=lx3",
            key: "9_1",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "连肖四肖",
            link: "trader/index?ball=lx4",
            key: "9_2",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "连肖五肖",
            link: "trader/index?ball=lx5",
            key: "9_3",
            szsz_amount: ["", "", "", "", "", ""],
        },
    ],
    lx: [
        {
            id: 150,
            name: "鼠",
            game_type: "连肖",
            nums: [
                { num: 6, color: "#4fca66" },
                { num: 18, color: "#fa3245" },
                { num: 30, color: "#fa3245" },
                { num: 42, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 157,
            name: "牛",
            game_type: "连肖",
            nums: [
                { num: 5, color: "#4fca66" },
                { num: 17, color: "#4fca66" },
                { num: 29, color: "#fa3245" },
                { num: 41, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 158,
            name: "虎",
            game_type: "连肖",
            nums: [
                { num: 4, color: "#2ea9e3" },
                { num: 16, color: "#4fca66" },
                { num: 28, color: "#4fca66" },
                { num: 40, color: "#fa3245" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 165,
            name: "兔",
            game_type: "连肖",
            nums: [
                { num: 3, color: "#2ea9e3" },
                { num: 15, color: "#2ea9e3" },
                { num: 27, color: "#4fca66" },
                { num: 39, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 166,
            name: "龙",
            game_type: "连肖",
            nums: [
                { num: 2, color: "#fa3245" },
                { num: 14, color: "#2ea9e3" },
                { num: 26, color: "#2ea9e3" },
                { num: 38, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 101,
            name: "蛇",
            game_type: "连肖",
            nums: [
                { num: 1, color: "#fa3245" },
                { num: 13, color: "#fa3245" },
                { num: 25, color: "#2ea9e3" },
                { num: 37, color: "#2ea9e3" },
                { num: 49, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 102,
            name: "马",
            game_type: "连肖",
            nums: [
                { num: 12, color: "#fa3245" },
                { num: 24, color: "#fa3245" },
                { num: 36, color: "#2ea9e3" },
                { num: 48, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 133,
            name: "羊",
            game_type: "连肖",
            nums: [
                { num: 11, color: "#4fca66" },
                { num: 23, color: "#fa3245" },
                { num: 35, color: "#fa3245" },
                { num: 47, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 134,
            name: "猴",
            game_type: "连肖",
            nums: [
                { num: 10, color: "#2ea9e3" },
                { num: 22, color: "#4fca66" },
                { num: 34, color: "#fa3245" },
                { num: 46, color: "#fa3245" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 141,
            name: "鸡",
            game_type: "连肖",
            nums: [
                { num: 9, color: "#2ea9e3" },
                { num: 21, color: "#4fca66" },
                { num: 33, color: "#4fca66" },
                { num: 45, color: "#fa3245" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 142,
            name: "狗",
            game_type: "连肖",
            nums: [
                { num: 8, color: "#fa3245" },
                { num: 20, color: "#2ea9e3" },
                { num: 32, color: "#4fca66" },
                { num: 44, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 149,
            name: "猪",
            game_type: "连肖",
            nums: [
                { num: 7, color: "#fa3245" },
                { num: 19, color: "#fa3245" },
                { num: 31, color: "#2ea9e3" },
                { num: 43, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
    ],
    //六肖一中
    lxyz_submenu_checked: "14_0",
    lxyz_submenu: [
        {
            title: "六肖一中",
            link: "trader/index?ball=lxyz",
            key: "14_0",
            szsz_amount: ["", "", "", "", "", ""],
        },
    ],
    lxyz: [
        {
            id: 1807,
            name: "鼠",
            game_type: "六肖一中",
            nums: [
                { num: 6, color: "#4fca66" },
                { num: 18, color: "#fa3245" },
                { num: 30, color: "#fa3245" },
                { num: 42, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 1808,
            name: "牛",
            game_type: "六肖一中",
            nums: [
                { num: 5, color: "#4fca66" },
                { num: 17, color: "#4fca66" },
                { num: 29, color: "#fa3245" },
                { num: 41, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 1809,
            name: "虎",
            game_type: "六肖一中",
            nums: [
                { num: 4, color: "#2ea9e3" },
                { num: 16, color: "#4fca66" },
                { num: 28, color: "#4fca66" },
                { num: 40, color: "#fa3245" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 1810,
            name: "兔",
            game_type: "六肖一中",
            nums: [
                { num: 3, color: "#2ea9e3" },
                { num: 15, color: "#2ea9e3" },
                { num: 27, color: "#4fca66" },
                { num: 39, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 1811,
            name: "龙",
            game_type: "六肖一中",
            nums: [
                { num: 2, color: "#fa3245" },
                { num: 14, color: "#2ea9e3" },
                { num: 26, color: "#2ea9e3" },
                { num: 38, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 1800,
            name: "蛇",
            game_type: "六肖一中",
            nums: [
                { num: 1, color: "#fa3245" },
                { num: 13, color: "#fa3245" },
                { num: 25, color: "#2ea9e3" },
                { num: 37, color: "#2ea9e3" },
                { num: 49, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 1801,
            name: "马",
            game_type: "六肖一中",
            nums: [
                { num: 12, color: "#fa3245" },
                { num: 24, color: "#fa3245" },
                { num: 36, color: "#2ea9e3" },
                { num: 48, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 1802,
            name: "羊",
            game_type: "六肖一中",
            nums: [
                { num: 11, color: "#4fca66" },
                { num: 23, color: "#fa3245" },
                { num: 35, color: "#fa3245" },
                { num: 47, color: "#2ea9e3" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 1803,
            name: "猴",
            game_type: "六肖一中",
            nums: [
                { num: 10, color: "#2ea9e3" },
                { num: 22, color: "#4fca66" },
                { num: 34, color: "#fa3245" },
                { num: 46, color: "#fa3245" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 1804,
            name: "鸡",
            game_type: "六肖一中",
            nums: [
                { num: 9, color: "#2ea9e3" },
                { num: 21, color: "#4fca66" },
                { num: 33, color: "#4fca66" },
                { num: 45, color: "#fa3245" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 1805,
            name: "狗",
            game_type: "六肖一中",
            nums: [
                { num: 8, color: "#fa3245" },
                { num: 20, color: "#2ea9e3" },
                { num: 32, color: "#4fca66" },
                { num: 44, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
        {
            id: 1806,
            name: "猪",
            game_type: "六肖一中",
            nums: [
                { num: 7, color: "#fa3245" },
                { num: 19, color: "#fa3245" },
                { num: 31, color: "#2ea9e3" },
                { num: 43, color: "#4fca66" },
            ],
            is_open: 1,
            maxpl: "",
            minpl: 0,
            pl: "",
            level_four: 0,
            level_three: 0,
            level_two: 3,
            control_let: 0,
            place: "2",
            szsz_amount: ["", "", "", "", "", "", ""],
        },
    ],
    //两面
    lianmian_t: [
        { id: 110, name: "特大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 111, name: "特小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 112, name: "特单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 113, name: "特双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    lianmian_h: [
        { id: 114, name: "合大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 115, name: "合小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 116, name: "合单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 117, name: "合双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
    ],
    lianmian_z: [
        { id: 118, name: "总大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 119, name: "总小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 120, name: "总单", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 121, name: "总双", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
    ],
    lianmian_w: [
        { id: 126, name: "家禽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 109, name: "野兽", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 122, name: "尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 123, name: "尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 124, name: "合尾大", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
        { id: 125, name: "合尾小", game_type: "两面", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] }, //奥六隐藏
    ],
    //尾数
    ws_tabs: [
        {
            title: "中",
            link: "trader/index?ball=ws",
            key: "ws",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "不中",
            link: "trader/index?ball=wsbz",
            key: "wsbz",
            szsz_amount: ["", "", "", "", "", ""],
        },
    ],
    ws: [
        { id: 175, name: "0尾", namenum: "0", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 176, name: "1尾", namenum: "1", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 178, name: "2尾", namenum: "2", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 179, name: "3尾", namenum: "3", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 180, name: "4尾", namenum: "4", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 181, name: "5尾", namenum: "5", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 182, name: "6尾", namenum: "6", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 183, name: "7尾", namenum: "7", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 184, name: "8尾", namenum: "8", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 185, name: "9尾", namenum: "9", game_type: "尾数", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    //尾连
    wl_tabs: [
        {
            title: "中",
            link: "trader/index?ball=wl",
            key: "wl",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "不中",
            link: "trader/index?ball=wlbz",
            key: "wlbz",
            szsz_amount: ["", "", "", "", "", ""],
        },
    ],
    wl_submenu_checked: "12_0",
    wl_submenu: [
        {
            title: "二尾连",
            link: "trader/index?ball=wl",
            key: "12_0",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "三尾连",
            link: "trader/index?ball=wl1",
            key: "12_1",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "四尾连",
            link: "trader/index?ball=wl2",
            key: "12_2",
            szsz_amount: ["", "", "", "", "", ""],
        },
        {
            title: "五尾连",
            link: "trader/index?ball=wl3",
            key: "12_3",
            szsz_amount: ["", "", "", "", "", ""],
        },
    ],
    wl: [
        { id: 1600, name: "连0尾", namenum: "0", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1601, name: "1尾", namenum: "1", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1602, name: "2尾", namenum: "2", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1603, name: "3尾", namenum: "3", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1604, name: "4尾", namenum: "4", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1605, name: "5尾", namenum: "5", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1606, name: "6尾", namenum: "6", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1607, name: "7尾", namenum: "7", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1608, name: "8尾", namenum: "8", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 1609, name: "9尾", namenum: "9", game_type: "尾连", color: "#000", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    //生肖/尾数量
    sxl: [
        { id: 2018, name: "肖2", namenum: "2", game_type: "生肖量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2019, name: "肖3", namenum: "3", game_type: "生肖量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2020, name: "肖4", namenum: "4", game_type: "生肖量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2021, name: "肖5", namenum: "5", game_type: "生肖量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2022, name: "肖6", namenum: "6", game_type: "生肖量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2023, name: "肖7", namenum: "7", game_type: "生肖量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
    wsl: [
        { id: 2024, name: "尾2", namenum: "2", game_type: "尾数量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2025, name: "尾3", namenum: "3", game_type: "尾数量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2026, name: "尾4", namenum: "4", game_type: "尾数量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2027, name: "尾5", namenum: "5", game_type: "尾数量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2028, name: "尾6", namenum: "6", game_type: "尾数量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
        { id: 2029, name: "尾7", namenum: "7", game_type: "尾数量", is_open: 1, maxpl: "", minpl: 0, pl: "", level_four: 0, level_three: 0, level_two: 3, control_let: 0, place: "2", szsz_amount: ["", "", "", "", "", "", ""] },
    ],
};